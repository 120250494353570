.App {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

.app-content {
  background-color: #f8e8d8;
  flex-grow: 1;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

@import url('https://fonts.googleapis.com/css2?family=Pacifico&display=swap');
/* @import url('https://fonts.googleapis.com/css2?family=Rubik&display=swap'); */
/* @import url('https://fonts.googleapis.com/css2?family=Lato&display=swap'); */
/* @import url('https://fonts.googleapis.com/css2?family=Montserrat&display=swap'); */
/* @import url('https://fonts.googleapis.com/css2?family=Open+Sans&display=swap'); */
/* @import url('https://fonts.googleapis.com/css2?family=Raleway&display=swap'); */
/* @import url('https://fonts.googleapis.com/css2?family=Poppins&display=swap'); */

.App-header, .fortune-cookie {
  background-color: #f8e8d8; /* Sets the background color of the header */  
  display: flex; /* Enables flexbox layout, making it easier to center content */
  flex-direction: column; /* Stacks flex items vertically */
  align-items: center; /* Centers flex items horizontally within the container */
  justify-content: center; /* Centers flex items vertically within the container */
  font-size: calc(12px + 2vmin); /* Sets a responsive font size, starting at 12px plus 2% of the viewport's smaller dimension */
  color: #8b4513; /* Sets the text color */
  font-family: 'Pacifico', sans-serif; /* Sets the font family with a fallback to any cursive font */
  /* background-image: url('path/to/your/background-image.jpg'); */ /* Placeholder for adding a background image */
  background-size: cover; /* Ensures the background image covers the entire element */
  background-position: center; /* Centers the background image within the element */
  position: relative; /* Sets the positioning context for absolutely positioned child elements */  
}

.App-header::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(255, 255, 255, 0.8);
  z-index: -1;
}

.app-footer {
  margin-top: auto;
  padding: 20px;
  text-align: center;
  background-color: #f8e8d8;
  color: #333;
  font-family: sans-serif;
  font-size: 24px;
}


.app-footer a {
  color: #007bff; /* Bootstrap's default link color */
  text-decoration: none;
  font-size: 24px;
}

.app-footer a:hover {
  text-decoration: underline;
}