.image-cycler-container {
    background-color: #f8e8d8;
    display: flex;
    justify-content: center;
    width: 100%;
  }
  
  .cycling-image {
    max-width: 300px;
    max-height: 300px;
    height: auto;
    width: auto;
    cursor: pointer;
    transition: transform 0.3s ease-in-out;
  }
  
  .cycling-image:hover {
    transform: scale(1.05);
  }
  
  .image-cycler {
    max-width: 80%;
    margin: 0 auto;
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    flex-direction: row;
    gap: 20px;
  }
  
  @media (max-width: 768px) {
    .image-cycler img {
      width: 100%;
      height: auto;
    }
    .image-cycler {
      max-width: 100%;
      flex-direction: column;
      gap: 10px;
    }
  }
  
  .cycling-image-wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  
  .clickable-hint {
    margin-top: 10px;
    font-size: 16px;
    color: #888;
    text-align: center;
  }
  
  .fortune-text-container {
    position: relative;
    margin: 40px auto 0;
    display: block;
    width: 300px;
    padding: 10px;
    min-height: 100px;
    text-align: center;
    font-family: sans-serif;
    font-size: 24px;
  }
  
  @keyframes fadeIn {
    from {
      opacity: 0;
    }
    to {
      opacity: 1;
    }
  }
  
  .cycling-image.animate,
  .fortune-text-container.animate-reveal {
    animation: fadeIn 1s ease-out;
  }

  .mobile-only {
    display: none;
  }
  
  @media (max-width: 768px) {
    .mobile-only {
      display: block;
    }
  }